@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif, -apple-system, BlinkMacSystemFont;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6, p, a, button, input{
  font-family: 'Open Sans', sans-serif !important;
}

code {
  font-family: 'Open Sans', sans-serif;
}

.login {
  background: #002d57;
  height: 100vh;
}

.header-top {
  background: #002d57;
  font-weight: 800;
  color: #fff;
  font-size: large;
  padding: 10px;
}

.header-top a{
  color: #fff;
  text-decoration: none;
  font-size: large;
}

.login h1, .login h2, .login h3{
  color: #f1f1f1;
}

.login-form input{
  background: transparent;
  color: #f1f1f1;
  border: none;
  padding: 8px 0px;
  border-bottom: 1px solid #9D9D9D;
  border-radius: 0;
  margin-bottom: 15px;
}

input.form-control:focus{
  background: transparent;
  border: none;
  box-shadow:none;
  color:#f1f1f1;
  border-bottom: 1px solid #f1f1f1;
}

input.form-control::placeholder {
  color: #9D9D9D;
}

.login button{
    width: 100%;
    background: #F59355;
    color: #f1f1f1;
    font-weight: 600;
}

.login button:hover{
  background:#dc8045;
  color: #f1f1f1;
}

.filepond--drop-label{
    border: 2px dashed #F59355 !important;
    background-color: white !important;
    border-radius: 30px;
    height: 100% !important;
}

.filepond--root.filepond--hopper{
  height: 270px !important;
}

.filepond--panel-root{
  background-color: white !important;
}


.filepond--label-action{ 
  padding: 8px 15px;
    border: 2px solid #F59355;
    border-radius: 8px;
    margin-top: 20px;
    display: block;
    width: 180px;
    font-size: larger;
    font-weight: 500;
    text-decoration: auto;  
}
/* .filepond--drop-label.filepond--drop-label label{
  display: flex;
    flex-direction: column;
    align-items: center;
} */
.filepond--drop-label label{
    display: flex !important;
    flex-direction: column;
    align-items: center;
}

.filepond--drop-label label img{
  width: 30px;
  margin-bottom: 14px;
}

button.btn-orange, a.btn-orange {
  background: #F59355;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  padding: 6px 27px;
}

button svg, a svg{
  font-size: 22px;
}

button.btn-orange:hover, a.btn-orange:hover {
  background: #dc783a;
  color: #fff;
}

button.btn-skyblue, a.btn-skyblue{
  background: #06D7FB;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  padding: 6px 27px;
}
button.btn-skyblue:hover, a.btn-skyblue:hover{
  background: #18b0ca;
  color: #fff;
}

button.btn-darkblue, a.btn-darkblue{
  background: #002F5B;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  padding: 6px 27px;
}
button{
  border:none !important
}
button.btn-darkblue:hover, a.btn-darkblue:hover{
  background: #021a2f;
  color: #fff;
}

.file-data .file-info{
  display: flex;
  flex-direction: column;
  align-items: center;
  
}
.file-data .file-info p{
  letter-spacing: 1px;
  font-weight: 300;
  font-size: 11px;
}
.file-data .file-info p span{
  font-weight: 600;
}
.file-data .file-info p.head{
  border-bottom: 2px solid #F59355;
  padding: 0 6px;
  margin-bottom: 5px;
  letter-spacing: 1px;
  font-weight: 600;
  font-size: 12px;
}

.table-head-action{
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  align-items: center;
}

table tr th.head{
  background: #EAEAEA;
}

table.table{
  margin: 0;
}

table tr td, table tr th{
  border: 0;
  border-right: 1px solid #9D9D9D;
  text-align: center;
  padding: 15px 5px !important;
}

table tr td:last-child, table tr th:last-child {
  border-right: 0;
}

.table-pagination{
  background:#002F5B ;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  display: flex;
  color: #ffff;
  font-size: 12px;
  flex-direction: row-reverse;
  align-items: center;
  padding: 8px 15px;
}

.table-pagination p{
  margin: 0;
}

table.table.tbl-view tr th.head {
  background: #fff;
  border-top: 1px solid #e4e4e4;
  border-bottom: 1px solid #e4e4e4;
}
table.table.tbl-view tbody tr{
  border-bottom: 1px solid #e4e4e4;
}
table.table.tbl-view tr td{
  background: #fff;
  border-bottom: 1px solid #e4e4e4;
}

table.table.tbl-view tr td, table.table.tbl-view tr th {
  border: none;
  /* border-right: 1px solid #9D9D9D; */
  font-size: 12px;
  text-align: left;
  padding: 15px 5px !important;
}

.border-1{
  border: 1px solid;
}

.error{
  text-align: left;
    color: #ad1414;
    font-size: 14px;
    margin: 5px 0px;
}

button:disabled{
  border: 1px solid #999999 !important;
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
  pointer-events: all !important;
}

a.back-btn{
  display: flex;
  /* flex-wrap: wrap; */
  /* flex-direction: row; */
  position: absolute;
  /* justify-content: center; */
  /* align-content: center; */
  margin-top: 6px;
}

a.back-btn svg{
  font-size: 30px;;
}

.table-head-left{
  display: flex;
    flex-direction: row;
    /* align-content: center; */
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
}

.table-head-left label{
  margin: 0;
    margin-right: 10px;
}

.table-pagination ul{
  margin: 0;
}

.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: block;
  border-top: 4px solid #000;
  border-right: 4px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.loader::after {
  content: '';  
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border-bottom: 4px solid #FF3D00;
  border-left: 4px solid transparent;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 

.spin-wrap {
    display: grid;
    place-items: center;
}